import React from 'react'
import 'twin.macro'
import { Link } from '../../../utils/general'
export default function CTA() {
    return (
<div tw="bg-gray-50 w-full mt-12">
  <div tw="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
    <h2 tw="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
      <span tw="block">Ready to start a project?</span>
      <span tw="block text-secondary-900">Contact our team today.</span>
    </h2>
    <div tw="mt-8 flex lg:mt-0 lg:flex-shrink-0">
      <div tw="inline-flex rounded-md shadow">
        <Link to="#" tw="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white hover:text-white bg-secondary-900 hover:bg-secondary-700 hover:no-underline">
          Let's do this
        </Link>
      </div>
      <div tw="ml-3 inline-flex rounded-md shadow">
        <Link to="#" tw="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-secondary-900 hover:text-secondary-900 bg-white hover:bg-secondary-100 hover:no-underline">
          Get Started
        </Link>
      </div>
    </div>
  </div>
</div>

    )
}
